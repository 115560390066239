export const environment = {
  production: false,
  apiUrl: 'https://apigw-stg.mzic.io',
  apiUrlLegacy: 'https://api-stg.mzic.io',
  imagesUrl: 'https://revelatorstage.blob.core.windows.net/images',
  cdnUrl: 'https://cdn.dev.mzic.io',
  mainUrl: 'https://www-stg.mzic.io',
  legalUrl: 'https://legal-stg.mzic.io',
  artistsUrl: 'https://artists-stg.mzic.io/',
  apiInstagram: 'https://api.instagram.com/oauth/authorize',
  urlCallbackInstagram: 'https://artists-stg.mzic.io/create-account/artist',
  clientIdInstagram: '661448082731585',
  instagramUS: 'https://www.instagram.com/mzic.io',
  instagramBR: 'https://www.instagram.com/mzicbrasil',
  posthogKeyProjectMain: 'phc_wGBvFQ9jtnu9P14uhtWud9auHnRHZ6tZKBpMU2UOJc6',
  posthogKeyProjectArtists: 'phc_tbQC24nqT8LypPRjEjKBnAt8OgjslNOZsM09vEPidq8',
  posthogKeyProjectLegal: 'phc_9xnX9xtQ7bEJs0cfpVeudaeXdbBNjXIcvhm8VqcYfXt',
  posthogHost: 'https://us.i.posthog.com',
};
